import React from 'react';
import HeroSlider from '../components/HeroSlider';

import ProductSection from '../components/Products';
import ServiceSection from '../components/Services';

import AboutUsSection from '../components/Inspire';
import ServiceHome2 from '../components/Service2';
import ContactHome from '../components/HomeContact';
import RollingProducts from '../components/RollingProducts';

const Home = () => {

  return (
  <>
  <div className=''>
  <HeroSlider/>
  <ServiceSection/>
  <RollingProducts/>
  <ServiceHome2/>
  <ContactHome/>
  </div>
   
   
  </>


  )
};

export default Home;
