import Image11 from '../assets/img/rolling/rolling11.jpg';
import Image21 from '../assets/img/rolling/rolling21.jpg';
import Image31 from '../assets/img/rolling/rolling31.jpg';
import Image41 from '../assets/img/rolling/rolling41.jpeg';
import Image51 from '../assets/img/rolling/rolling51.jpg';
import Image61 from '../assets/img/rolling/rolling61.jpg';
import Image71 from '../assets/img/rolling/rolling71.jpg';
import Image81 from '../assets/img/rolling/rolling81.jpg';
import Image91 from '../assets/img/rolling/rolling91.jpg';
import Image101 from '../assets/img/photos/forklift1.png';
import Image102 from '../assets/img/photos/forklift2.png';
import Image103 from '../assets/img/photos/forklift3.png';
import Image104 from '../assets/img/photos/forklift4.png';
import Image105 from '../assets/img/photos/forklift5.png';
import Image106 from '../assets/img/photos/forklift6.png';
import Image111 from '../assets/img/photos/crane1.jpg';
import Image112 from '../assets/img/photos/crane2.jpg';
import Image113 from '../assets/img/photos/crane3.jpg';
import Image114 from '../assets/img/photos/crane4.jpg';

export const RollingMachines = [
  { 
    id: 1, 
    name: 'High speed type single sheet roll forming machine', 
    images:  [Image11], 
    description: `This high-speed roll forming machine is designed to efficiently manufacture metal profiles according to your specific requirements. With over 20 years of R&D and production experience, we offer customized design options tailored to your profile drawing, material, and thickness preferences. Our machine exceeds industry standards, providing exceptional reliability and productivity.`,
    technicalDetails: {
      rawMaterial: 'GI, GL, PPGI, PPGL',
      thicknessOfCoilSheet: '0.3-0.8 mm',
      machineDetails: {
        rollerMaterial: 'Forming rollers adopt 45# steel, grinded, polished, and hard chromium plated for high precision.',
        shaftMaterial: 'Shafts of forming adopt 45# steel, balanced and corrected for precision, with stainless steel dust shields for bearings.',
        diameterOfShaft: '75mm',
        mainFrame: '350 H steel',
        formingSteps: '16 rows',
        productivity: '25m/min',
        hydraulicStationPower: '5.5 KW',
        controllingSystem: 'Famous PLC computer control',
        materialOfCuttingTable: 'Cr 12 mould steel with quenched treatment',
        hydraulicOil: '40# hydraulic oil'
      }
    }
  },
  { 
    id: 2, 
    name: 'Corrugated sheet roll forming machine', 
    images:  [Image21], 
    description: `Our corrugated sheet roll forming machine is engineered to produce high-quality corrugated metal sheets with precision and efficiency. With advanced technology and robust construction, this machine ensures optimal performance and durability. Whether for roofing or cladding applications, our machine delivers consistent results and meets the demands of various industries.`,
    technicalDetails: {
      rawMaterial: 'GI, GL, PPGI, PPGL',
      thicknessOfCoilSheet: '0.3-0.8 mm',
      machineDetails: {
        rollerMaterial: 'Forming rollers adopt 45# steel, grinded, polished, and hard chromium plated for high precision.',
        shaftMaterial: 'Shafts of forming adopt 45# steel, balanced and corrected for precision, with stainless steel dust shields for bearings.',
        diameterOfShaft: '75mm',
        mainFrame: '350 H steel',
        formingSteps: '16 rows',
        productivity: '25m/min',
        hydraulicStationPower: '5.5 KW',
        controllingSystem: 'Famous PLC computer control',
        materialOfCuttingTable: 'Cr 12 mould steel with quenched treatment',
        hydraulicOil: '40# hydraulic oil'
      }
    }
  },
  { 
    id: 3, 
    name: 'Double layer roll forming machine', 
    images: [Image31], 
    description: `Our double layer roll forming machine is designed for versatility and efficiency in producing two different profiles on the same machine. With its dual-head design and advanced control system, this machine offers increased productivity and flexibility in meeting diverse production needs. Whether for roofing, wall cladding, or other applications, our machine ensures consistent quality and performance.`,
    technicalDetails: {
      rawMaterial: 'GI, GL, PPGI, PPGL',
      thicknessOfCoilSheet: '0.3-0.8 mm',
      machineDetails: {
        rollerMaterial: 'Forming rollers adopt 45# steel, grinded, polished, and hard chromium plated for high precision.',
        shaftMaterial: 'Shafts of forming adopt 45# steel, balanced and corrected for precision, with stainless steel dust shields for bearings.',
        diameterOfShaft: '75mm',
        mainFrame: '350 H steel',
        formingSteps: '16 rows',
        productivity: '25m/min',
        hydraulicStationPower: '5.5 KW',
        controllingSystem: 'Famous PLC computer control',
        materialOfCuttingTable: 'Cr 12 mould steel with quenched treatment',
        hydraulicOil: '40# hydraulic oil'
      }
    }
  },
  { 
    id: 4, 
    name: 'Trapezoidal sheet roll forming machine', 
    images: [Image41], 
    description: `Our trapezoidal sheet roll forming machine is engineered to produce high-quality trapezoidal metal sheets with precision and efficiency. With its robust construction and advanced features, this machine ensures smooth and reliable operation, meeting the demands of various roofing and cladding applications. From residential to industrial projects, our machine delivers superior performance and consistent results.`,
    technicalDetails: {
      rawMaterial: 'GI, GL, PPGI, PPGL',
      thicknessOfCoilSheet: '0.3-0.8 mm',
      machineDetails: {
        rollerMaterial: 'Forming rollers adopt 45# steel, grinded, polished, and hard chromium plated for high precision.',
        shaftMaterial: 'Shafts of forming adopt 45# steel, balanced and corrected for precision, with stainless steel dust shields for bearings.',
        diameterOfShaft: '75mm',
        mainFrame: '350 H steel',
        formingSteps: '16 rows',
        productivity: '25m/min',
        hydraulicStationPower: '5.5 KW',
        controllingSystem: 'Famous PLC computer control',
        materialOfCuttingTable: 'Cr 12 mould steel with quenched treatment',
        hydraulicOil: '40# hydraulic oil'
      }
    }
  },
  { 
    id: 5, 
    name: 'Wall panel sheet roll forming machine:V125-750', 
    images: [Image51], 
    description: `Our wall panel sheet roll forming machine (V125-750) is designed to produce high-quality wall panels with precision and efficiency. With its versatile design and advanced control system, this machine offers flexibility in creating various panel profiles to meet different architectural and construction requirements. From residential to commercial projects, our machine ensures superior panel quality and performance.`,
    technicalDetails: {
      rawMaterial: 'GI, GL, PPGI, PPGL',
      thicknessOfCoilSheet: '0.3-0.8 mm',
      machineDetails: {
        rollerMaterial: 'Forming rollers adopt 45# steel, grinded, polished, and hard chromium plated for high precision.',
        shaftMaterial: 'Shafts of forming adopt 45# steel, balanced and corrected for precision, with stainless steel dust shields for bearings.',
        diameterOfShaft: '75mm',
        mainFrame: '350 H steel',
        formingSteps: '16 rows',
        productivity: '25m/min',
        hydraulicStationPower: '5.5 KW',
        controllingSystem: 'Famous PLC computer control',
        materialOfCuttingTable: 'Cr 12 mould steel with quenched treatment',
        hydraulicOil: '40# hydraulic oil'
      }
    }
  },
  { 
    id: 6, 
    name: 'Auto interchangeable CZ purlin roll forming machine', 
    images: [Image61], 
    description: `Our auto interchangeable CZ purlin roll forming machine is engineered for efficient production of CZ purlins with precision and speed. With its automatic changeover system and advanced control technology, this machine offers seamless transition between different purlin sizes, reducing downtime and increasing productivity. From small-scale to large-scale projects, our machine ensures consistent purlin quality and performance.`,
    technicalDetails: {
      rawMaterial: 'GI, GL, PPGI, PPGL',
      thicknessOfCoilSheet: '0.3-0.8 mm',
      machineDetails: {
        rollerMaterial: 'Forming rollers adopt 45# steel, grinded, polished, and hard chromium plated for high precision.',
        shaftMaterial: 'Shafts of forming adopt 45# steel, balanced and corrected for precision, with stainless steel dust shields for bearings.',
        diameterOfShaft: '75mm',
        mainFrame: '350 H steel',
        formingSteps: '16 rows',
        productivity: '25m/min',
        hydraulicStationPower: '5.5 KW',
        controllingSystem: 'Famous PLC computer control',
        materialOfCuttingTable: 'Cr 12 mould steel with quenched treatment',
        hydraulicOil: '40# hydraulic oil'
      }
    }
  },
  { 
    id: 7, 
    name: 'Mould cutting type C purlin roll forming machine', 
    images: [Image71], 
    description: `Our mould cutting type C purlin roll forming machine is designed for precision manufacturing of C purlins with mould cutting technology. With its high-speed operation and accurate cutting mechanism, this machine ensures smooth and uniform purlin production, meeting the strictest quality standards. Whether for structural or architectural applications, our machine delivers reliable performance and consistent results.`,
    technicalDetails: {
      rawMaterial: 'GI, GL, PPGI, PPGL',
      thicknessOfCoilSheet: '0.3-0.8 mm',
      machineDetails: {
        rollerMaterial: 'Forming rollers adopt 45# steel, grinded, polished, and hard chromium plated for high precision.',
        shaftMaterial: 'Shafts of forming adopt 45# steel, balanced and corrected for precision, with stainless steel dust shields for bearings.',
        diameterOfShaft: '75mm',
        mainFrame: '350 H steel',
        formingSteps: '16 rows',
        productivity: '25m/min',
        hydraulicStationPower: '5.5 KW',
        controllingSystem: 'Famous PLC computer control',
        materialOfCuttingTable: 'Cr 12 mould steel with quenched treatment',
        hydraulicOil: '40# hydraulic oil'
      }
    }
  },{ 
    id: 8, 
    name: 'Special C purlin type for mobile board room', 
    images: [Image81], 
    description: `Our special C purlin type for mobile board room is specifically designed for manufacturing C purlins used in mobile board room construction. With its specialized design and advanced features, this machine ensures precise and efficient production of custom-sized purlins to meet the unique requirements of mobile board room structures. From durability to flexibility, our machine delivers superior performance and quality.`,
    technicalDetails: {
      rawMaterial: 'GI, GL, PPGI, PPGL',
      thicknessOfCoilSheet: '0.3-0.8 mm',
      machineDetails: {
        rollerMaterial: 'Forming rollers adopt 45# steel, grinded, polished, and hard chromium plated for high precision.',
        shaftMaterial: 'Shafts of forming adopt 45# steel, balanced and corrected for precision, with stainless steel dust shields for bearings.',
        diameterOfShaft: '75mm',
        mainFrame: '350 H steel',
        formingSteps: '16 rows',
        productivity: '25m/min',
        hydraulicStationPower: '5.5 KW',
        controllingSystem: 'Famous PLC computer control',
        materialOfCuttingTable: 'Cr 12 mould steel with quenched treatment',
        hydraulicOil: '40# hydraulic oil'
      }
    }
  },




        { 
          id: 9, 
          name: 'The infinite cutting type C purlin machine', 
          images: [Image91], 
          description: `Our infinite cutting type C purlin machine is engineered to provide unlimited cutting capabilities for producing C purlins with precise dimensions. With its innovative design and advanced cutting technology, this machine offers unparalleled flexibility and efficiency in C purlin production, allowing for seamless customization and optimization of purlin profiles. From standard to complex shapes, our machine delivers exceptional cutting accuracy and versatility.`,
          technicalDetails: {
            rawMaterial: 'GI, GL, PPGI, PPGL',
            thicknessOfCoilSheet: '0.3-0.8 mm',
            machineDetails: {
              rollerMaterial: 'Forming rollers adopt 45# steel, grinded, polished, and hard chromium plated for high precision.',
              shaftMaterial: 'Shafts of forming adopt 45# steel, balanced and corrected for precision, with stainless steel dust shields for bearings.',
              diameterOfShaft: '75mm',
              mainFrame: '350 H steel',
              formingSteps: '16 rows',
              productivity: '25m/min',
              hydraulicStationPower: '5.5 KW',
              controllingSystem: 'Famous PLC computer control',
              materialOfCuttingTable: 'Cr 12 mould steel with quenched treatment',
              hydraulicOil: '40# hydraulic oil'
            }
          }
        },

        { 
          id: 10, 
          name: 'Forklift', 
          images: [Image101, Image102, Image103.Image104, Image105, Image106], 
          description: `Our infinite cutting type C purlin machine is engineered to provide unlimited cutting capabilities for producing C purlins with precise dimensions. With its innovative design and advanced cutting technology, this machine offers unparalleled flexibility and efficiency in C purlin production, allowing for seamless customization and optimization of purlin profiles. From standard to complex shapes, our machine delivers exceptional cutting accuracy and versatility.`,
          // technicalDetails: {
          //   rawMaterial: 'GI, GL, PPGI, PPGL',
          //   thicknessOfCoilSheet: '0.3-0.8 mm',
          //   machineDetails: {
          //     rollerMaterial: 'Forming rollers adopt 45# steel, grinded, polished, and hard chromium plated for high precision.',
          //     shaftMaterial: 'Shafts of forming adopt 45# steel, balanced and corrected for precision, with stainless steel dust shields for bearings.',
          //     diameterOfShaft: '75mm',
          //     mainFrame: '350 H steel',
          //     formingSteps: '16 rows',
          //     productivity: '25m/min',
          //     hydraulicStationPower: '5.5 KW',
          //     controllingSystem: 'Famous PLC computer control',
          //     materialOfCuttingTable: 'Cr 12 mould steel with quenched treatment',
          //     hydraulicOil: '40# hydraulic oil'
          //   }
          // }
        },
        { 
          id: 11, 
          name: 'Overhead Crane', 
          images: [Image111, Image112, Image113, Image114], 
          description: 'The overhead crane, also known as a bridge crane, is a type of industrial lifting equipment used for transporting and lifting heavy loads in workshops, warehouses, and manufacturing facilities. It consists of parallel runways with a traveling bridge spanning the gap and a hoist, which is used to lift and lower materials. Overhead cranes are essential for efficient material handling and come in various configurations to suit different applications.'
        }
        
      
        
  // Add more products with similar structures as needed
];
