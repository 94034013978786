import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import Header from './components/Header';
import Home from './pages/Home';
import ScrollToTop from './components/ScrollTop';
import ProductsPage from './pages/Products';
import ProductDetail from './pages/ProductDetail';
import { products } from './data/products';
import { RollingMachines } from './data/rollingproducts';
import AboutUs from './pages/About';
import Contact from './components/HomeContact';
import ContactUs from './pages/Contact';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";

// Define the products array


const App = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
 
  return (
    <Router>
      <div>
        <Header />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          {/* Pass the products array to the ProductsPage component */}
          <Route path="/products" element={<ProductsPage products={products} />} />
          <Route path="/product/:productId" element={<ProductDetail products={RollingMachines} />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
          
          {/* Other routes */}
        </Routes>
        <Footer/>
      </div>
    </Router>
  );
};

export default App;
