import React, { useState, useEffect } from "react";
import { FaHome } from "react-icons/fa";
import { useLocation, Link } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import { FaGgCircle } from "react-icons/fa";



const Header = () => {
  const location = useLocation();

  // Determine if the current route is '/signin'
  const validPaths = ["/products", "/about", "/contact"];

// Check if the current path is a valid path or starts with "/product/"
const isProductPath = /^\/product\//.test(location.pathname);

if (isProductPath) {
  validPaths.push(location.pathname);
}

const Paths = validPaths.includes(location.pathname);


  const DontChangeColor = Paths;
  // Define a class for the header text based on whether it's on '/signin' or not
  const headerTextClass = DontChangeColor ? "text-primary" : "";
  const [header, setHeader] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.scrollY > 50 ? setHeader(true) : setHeader(false);
    });
  });

  return (
    <header
      className={`${
        header ? "bg-white  py-6 shadow-lg" : "bg-transparent  py-8"
      } fixed z-50 w-full transition-all duration-500`}
    >
      <div className="container mx-auto flex flex-col items-center gap-y-6 lg:flex-row lg:justify-between lg:gap-y-0">
        <Link to="/">
          {header ? (
            <div>
              <Link
                to="/"
                className="flex justify-center items-center flex-row gap-3"
              > 
                <FaGgCircle className="w-8 h-8 text-blue-700" />
                <span className=" text-primary text-xl font-primary">
                Gaiweike
                </span>
              </Link>
            </div>
          ) : (
            <div>
              <Link
                to="/"
                className="flex justify-center items-center flex-row gap-3"
              >
                <FaGgCircle className="w-8 h-8 text-blue-700" />
                <span 
                
                
                className= {` ${headerTextClass}  text-white text-xl font-primary`}
                
                
                
                
                >
                  Gaiweike
                </span>
              </Link>
            </div>
          )}
        </Link>
        <nav
          className={` ${headerTextClass} ${
            header ? "text-primary" : "text-white"
          } flex gap-x-4 lg:gap-x-8 font-tertiary tracking-[3px] text-[15px] items-center uppercase`}
        >
          <Link to="/" className="hover:text-accent transition">
            Home
          </Link>
          <Link to="/products" className="hover:text-accent transition">
            Products
          </Link>

          <Link to="/about" className="hover:text-accent transition">
            About
          </Link>
          <Link to="/contact" className="hover:text-accent transition">
            Contact
          </Link>
        </nav>
      </div>
    </header>
  );
};

export default Header;
