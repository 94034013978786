

import React from 'react';
import { Link } from 'react-router-dom';
import { RollingMachines } from '../data//rollingproducts';
import SectionHeader from './SectionHeader';
import ProductSection from './Products';

const truncateDescription = (description, maxLength) => {
  if (description.length <= maxLength) {
    return description;
  }
  return description.substring(0, maxLength).trim() + '...';
};

const RollingProducts = () => {
  const maxLength = 100; // Set the maximum length for the description
  
  return (
    <section className="py-16 bg-gray-100">
      <div className="container mx-auto px-4">
        {/* <h2 className="text-3xl font-semibold text-gray-800 mb-8">Featured Machinery</h2> */}
                <SectionHeader className="" title="Our Products" description="Our manufacturing solutions optimize efficiency and productivity while ensuring "/>

        <div className="grid grid-cols-1 sm:grid-cols-2 mt-16 lg:grid-cols-3 gap-8">
          {RollingMachines.map(product => (
            <div key={product.id} 
            data-aos="fade-up"
     data-aos-duration="3000"
            
            
            className="bg-white rounded-lg overflow-hidden hover:shadow-lg group  hover:border-blue-500 hover:border-2 transition-shadow duration-300 ease-in-out">
              <img src={product.images[0]} alt={product.name} className="w-full h-64 object-cover object-center transition duration-300 transform group-hover:scale-105" />
              <div className="p-6">
                <h3 className="text-lg font-semibold text-gray-800 mb-2">{product.name}</h3>
                <p className="text-gray-600 mb-4">{truncateDescription(product.description, maxLength)}</p>
                <Link  to={`/product/${product.id}`} className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md transition duration-300 ease-in-out transform hover:translate-y-0.5">See More</Link>
              </div>
            </div>
          ))}
        </div>

      </div>


    </section>
  );
};

export default RollingProducts;

