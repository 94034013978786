import React, { useEffect } from 'react';
import { FiArrowRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import ScrollReveal from 'scrollreveal';

export default function Contact() {
    useEffect(() => {
        ScrollReveal().reveal('.contact__container', {
            origin: 'bottom',
            distance: '60px',
            duration: 2000,
            delay: 300,
            easing: 'ease-in-out',
            reset: true
        });
    }, []);

    return (
        <section className="contact mt-[80px] xl:my-[150px] relative z-20" id="contact">
            <div className="contact__container container mx-auto bg-gray-900 sm:rounded-[70px] py-[80px]">
                <div className="container__text max-w-[640px] mx-auto text-center">
                    <h2 className="h2 text-white mb-4">Get in Touch</h2>
                    <p className="text-white mb-8 max-w-sm xl:max-w-none mx-auto">
                        Need custom steel machinery solutions? Contact us now to discuss your requirements.
                    </p>
                    <Link to="/contact">
                        <button className="mx-auto btn bg-white text-gray-900 font-bold py-4 px-8 rounded-lg flex items-center justify-center transition duration-300 ease-in-out transform hover:scale-105">
                            Contact Us <FiArrowRight className="ml-2" />
                        </button>
                    </Link>
                </div>
            </div>
        </section>
    );
}
