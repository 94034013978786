import ImageGallery from "react-image-gallery";


export default function MyGallery({ images }) {
  console.log(images); // Log the images prop to check its value
  
  // Check if images is an array before calling map
  if (!Array.isArray(images)) {
    console.error('Images is not an array:', images);
    return null; // Return null or handle the error accordingly
  }

  // Map over the images array to create the array required for react-image-gallery
  const galleryImages = images.map(image => ({
    original: image,
    thumbnail: image,
  }));

  return (
    <ImageGallery showNav={false} showPlayButton={false} showBullets={true} items={galleryImages} />
  );
}
