import React from 'react';
import { FaTwitter, FaFacebook, FaInstagram } from 'react-icons/fa';
import { FiArrowDown } from 'react-icons/fi';
import { Link } from 'react-router-dom';

const AboutUs = () => {
  return (
    <div className="bg-gray-100 min-h-screen pt-32">
      {/* Vision and Mission Section */}
      <div data-aos="fade-up"
     data-aos-duration="3000" className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">Vision & Mission</h2>
          <div className="mt-4 text-lg text-gray-500">
            <p>
              <strong>Vision:</strong> To be a global leader in innovative machinery solutions.
            </p>
            <p>
              <strong>Mission:</strong> Providing high-quality products and services to empower our customers' success.
            </p>
          </div>
        </div>
      </div>

      {/* Inspiration Section */}
      <div data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000" className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8 bg-gray-800 text-white">
        <div className="text-center">
          <h2 className="text-3xl text-white font-extrabold">Our Inspiration</h2>
          <p className="mt-4 text-lg">
            Gaiweike is inspired by a commitment to excellence, innovation, and sustainability. 
            We strive to make a positive impact on our industry, communities, and the environment.
          </p>
        </div>
      </div>

     {/* Company Overview Section */}
<div data-aos="fade-up"
  data-aos-duration="3000" className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
  <div className="text-center">
    <h2 className="text-3xl  font-extrabold text-gray-900 sm:text-4xl">Company Overview</h2>
    <p className="mt-4 text-lg text-gray-500">
      Gaiweike, founded in 2000, is a leading manufacturer of machinery solutions. With a focus on quality, innovation, and customer satisfaction, Gaiweike offers a wide range of products and services tailored to meet the needs of various industries.
    </p>
    <p className="mt-4 text-lg text-gray-500">
      Our product lines include:
    </p>
    <ul className="mt-2 text-lg text-gray-500  list-disc list-inside">
      <li>High-speed type single sheet roll forming machine.</li>
      <li>Corrugated sheet roll forming machine.</li>
      <li>Double layer roll forming machine.</li>
      <li>Trapezoidal sheet roll forming machine.</li>
      <li>Auto interchangeable CZ purlin roll forming machine</li>
      <li>Mould cutting type C purlin roll forming machine</li>
      <li>Special C purlin type for mobile board room.</li>
      <li>The infinite cutting type C purlin machine</li>
      <li>Forklift</li>
      <li>Overhead Crane</li>
    </ul>
    {/* Add more sections or details as needed */}
  </div>
</div>


      {/* Connect with Us Section */}
      <div data-aos="fade-up"
     data-aos-duration="3000" className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">Connect with Us</h2>
          <div className="mt-4 flex justify-center items-center space-x-4">
          <Link to="/contact">
                        <button className="mx-auto btn bg-gray-700 text-white font-bold py-4 px-8 rounded-lg flex items-center justify-center transition duration-300 ease-in-out transform hover:scale-105">
                            Contact Us <FiArrowDown className="ml-2" />
                        </button>
                    </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
