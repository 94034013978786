import React, { createContext, useRef, useState } from 'react';
import { roomData } from '../data';

export const RoomContext = createContext();

const RoomProvider = ({ children }) => {
  const myRef = useRef(null);
  const [rooms, setRooms] = useState(roomData);
  const [loading, setLoading] = useState(false);

  // Define handleScroll function outside of handleClick
  const handleScroll = (e) => {
    console.log("button is working")
    e.preventDefault();
    myRef.current?.scrollIntoView({ behavior: 'smooth' });
  }

  const handleClick = (e) => {
    e.preventDefault();
    setLoading(true);
    handleScroll(e); // Call handleScroll here if needed

    // Return the context provider with value
    return (
      <RoomContext.Provider value={{ rooms, handleClick, loading, myRef, handleScroll }}>
        {children}
      </RoomContext.Provider>
    );
  };

  return (
    // Wrapping the handleClick function outside
    <RoomContext.Provider value={{ rooms, handleClick, loading, myRef, handleScroll }}>
      {children}
    </RoomContext.Provider>
  );
};

export default RoomProvider;
