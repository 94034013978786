// products.js
import Image11 from '../assets/img/photos/forklift1.png';
import Image12 from '../assets/img/photos/forklift2.png';
import Image13 from '../assets/img/photos/forklift3.png';
import Image14 from '../assets/img/photos/forklift4.png';
import Image15 from '../assets/img/photos/forklift5.png';
import Image16 from '../assets/img/photos/forklift6.png';
import Image21 from '../assets/img/photos/elevator1.png';
import Image22 from '../assets/img/photos/elevator2.png';
import Image23 from '../assets/img/photos/elevator3.png';
import Image24 from '../assets/img/photos/elevator4.png';
import Image25 from '../assets/img/photos/elevator5.png';
import Image26 from '../assets/img/photos/elevator6.png';
import Image31 from '../assets/img/photos/crawler1.png';
import Image32 from '../assets/img/photos/crawler2.png';
import Image33 from '../assets/img/photos/crawler3.png';
import Image34 from '../assets/img/photos/crawler4.png';
import Image35 from '../assets/img/photos/crawler5.png';
import Image36 from '../assets/img/photos/crawler6.png';
import Image41 from '../assets/img/photos/loader1.png';
import Image42 from '../assets/img/photos/loader2.png';
import Image43 from '../assets/img/photos/loader3.png';
import Image44 from '../assets/img/photos/loader4.png';
import Image51 from '../assets/img/photos/lifting1.png';
import Image52 from '../assets/img/photos/lifting2.png';
import Image53 from '../assets/img/photos/lifting3.png';
import Image54 from '../assets/img/photos/lifting4.png';




export const products = [
  { 
    id: 10, 
    name: 'Forklift', 
    images:  [Image11, Image12, Image13,Image14, Image15,Image16], 
    description: 'A forklift, also known as a lift truck or fork truck, is a powered industrial truck used for lifting, moving, and stacking materials in warehouses, distribution centers, and manufacturing facilities. It features fork attachments that can be raised and lowered for lifting palletized goods, containers, and other heavy loads. Forklifts come in various configurations, including electric, diesel, and gas-powered models, and are essential for material handling operations.' 
  },
  { 
    id: 11, 
    name: 'Overhead Crane', 
    images: [Image11, Image12, Image13, Image14, Image15], 
    description: 'The overhead crane, also known as a bridge crane, is a type of industrial lifting equipment used for transporting and lifting heavy loads in workshops, warehouses, and manufacturing facilities. It consists of parallel runways with a traveling bridge spanning the gap and a hoist, which is used to lift and lower materials. Overhead cranes are essential for efficient material handling and come in various configurations to suit different applications.',
    detailDescription: {
      europeanElectricHoist: 'Our European electric hoist overhead crane combines reliability and efficiency for your lifting needs. With its European-style design, this crane offers smooth operation and precise control, making it suitable for precision lifting tasks in industrial environments.',
      europeanStyleSingleGirder: 'The European-style single girder overhead crane is engineered for optimal performance and durability. Featuring a single girder design with European components, this crane offers high lifting capacities and excellent stability, making it ideal for heavy-duty lifting applications.',
      electricHoist: 'Our electric hoist overhead crane provides versatility and convenience for your material handling operations. With its electric hoist system, this crane offers efficient lifting and precise positioning of loads, enhancing productivity and safety in your workplace.'
    }
  }
  


  // { 
  //   id: 4, 
  //   name: 'Lifting Machine', 
  //   images: [Image51, Image52, Image53,Image54], 
  //   description: 'A lifting machine is a mechanical device used for lifting and lowering heavy objects in various industries. It includes cranes, hoists, and lifts, each designed for specific lifting applications. Lifting machines come in different types, such as overhead cranes, gantry cranes, tower cranes, and mobile cranes, providing versatile solutions for construction, manufacturing, and logistics tasks. These machines enhance productivity, safety, and efficiency in material handling operations.' 
  // },
  // { 
  //   id: 5, 
  //   name: 'Lifting Machine', 
  //   images: [Image41, Image42, Image43,Image44], 
  //   description: 'A lifting machine is a mechanical device used for lifting and lowering heavy objects in various industries. It includes cranes, hoists, and lifts, each designed for specific lifting applications. Lifting machines come in different types, such as overhead cranes, gantry cranes, tower cranes, and mobile cranes, providing versatile solutions for construction, manufacturing, and logistics tasks. These machines enhance productivity, safety, and efficiency in material handling operations.' 
  // },
  // { 
  //   id: 6, 
  //   name: 'Load Machine', 
  //   images:  [Image44, Image41, Image42, Image43], 
  //   description: 'A forklift, also known as a lift truck or fork truck, is a powered industrial truck used for lifting, moving, and stacking materials in warehouses, distribution centers, and manufacturing facilities. It features fork attachments that can be raised and lowered for lifting palletized goods, containers, and other heavy loads. Forklifts come in various configurations, including electric, diesel, and gas-powered models, and are essential for material handling operations.' 
  // },
  // Add more products with multiple images and descriptions as needed
];
