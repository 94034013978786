import React from "react";
import aboutImg1 from "../assets/img/photos/forklift1.png";
import aboutImg2 from "../assets/img/photos/forklift1.png";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

function ServiceHome2() {
  return (
    <section className="bg-gray-100 py-16">
      <div className="container mx-auto flex flex-col lg:flex-row items-center justify-between gap-8 px-4 lg:px-0">
        <div className="max-w-lg mx-auto lg:mx-0 lg:w-1/2">
          <img src={aboutImg1} alt="Installation" className="rounded-lg shadow-lg transition duration-500 transform hover:scale-105" />
        </div>
        <div data-aos="zoom-out"
  
  data-aos-duration="2000"
  data-aos-offset="0" className="max-w-lg mx-auto lg:mx-0 lg:w-1/2">
          <div className="bg-white rounded-lg shadow-lg p-8">
            <h2 className="text-3xl font-bold text-gray-800 mb-4">Welcome to Gaweike</h2>
            <p className="text-gray-600 mb-6">
              Gaweike is your trusted partner for precision machinery manufacturing solutions. With our cutting-edge technology and commitment to excellence, we deliver top-quality products and services tailored to meet your needs.
            </p>
            <Link to="/about" className="inline-block bg-indigo-500 text-white py-2 px-6 rounded-lg hover:bg-indigo-600 transition duration-300 ease-in-out">
              Learn More <FaArrowRight className="inline-block ml-2" />
            </Link>
          </div>
        </div>
      </div>

    </section>
  );
}

export default ServiceHome2;
