import React from 'react';
import { FaTools, FaCog, FaWrench  } from 'react-icons/fa';
import { SiCodesignal } from "react-icons/si";
import { SiAntdesign } from "react-icons/si";
import { MdDesignServices } from "react-icons/md";
import SectionHeader from './SectionHeader';

const services = [
  {
    id: 1,
    title: 'Custom Machinery Design',
    description: 'We specialize in designing custom machinery tailored to your specific needs and requirements.',
    icon: <MdDesignServices className='w-full h-full' />,
  },
  {
    id: 2,
    title: 'Manufacturing Solutions',
    description: 'Our manufacturing solutions optimize efficiency and productivity while ensuring high-quality output.',
    icon: <FaCog className='w-full h-full'  />,
  },
  {
    id: 3,
    title: 'Maintenance & Support',
    description: 'We provide comprehensive maintenance and support services to keep your machinery running smoothly.',
    icon: <FaTools className='w-full h-full'  />,
  }
];

const ServiceSection = () => {
  return (
    <section className="py-8 bg-gray-100">
      <div className="container mx-auto px-4">
        {/* <h2 className="text-3xl font-semibold text-gray-800 mb-8">Our Services</h2> */}
        <SectionHeader className="" title="Our Services" description="Our manufacturing solutions optimize efficiency and productivity while ensuring "/>
        <div className="grid grid-cols-1  sm:grid-cols-2 lg:grid-cols-3 mt-20 gap-8">
          {services.map(service => (
            <div key={service.id} className="bg-white rounded-lg shadow-md overflow-hidden flex flex-col items-center transition duration-300 ease-in-out transform hover:bg-blue-500 hover:text-white hover:scale-105">
              <div className="w-24 h-24 mt-8 mb-4">{service.icon}</div>
              <div className="px-6 py-4 text-center">
                <h3 className="text-xl font-semibold mb-2">{service.title}</h3>
                <p className="text-gray-600">{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServiceSection;

// import React from 'react';
// import { FaTools, FaCog, FaWrench } from 'react-icons/fa'; // Import different icons
// import SectionHeader from './SectionHeader';

// const services = [
//   {
//     id: 1,
//     title: 'Custom Machinery Design',
//     description: 'We specialize in designing custom machinery tailored to your specific needs and requirements.',
//     icon: <FaTools />,
//   },
//   {
//     id: 2,
//     title: 'Manufacturing Solutions',
//     description: 'Our manufacturing solutions optimize efficiency and productivity while ensuring high-quality output.',
//     icon: <FaCog />,
//   },
//   {
//     id: 3,
//     title: 'Maintenance & Support',
//     description: 'We provide comprehensive maintenance and support services to keep your machinery running smoothly.',
//     icon: <FaWrench />,
//   }
// ];

// const ServiceSection = () => {
//   return (
//     <section className="py-2 bg-gray-100">
//       <div className="container mx-auto px-4">
//         <SectionHeader title="Our Services" description="Explore our range of services designed to meet your needs." />
//         <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-20 gap-8">
//           {services.map(service => (
//             <div key={service.id} className="bg-white rounded-lg shadow-md overflow-hidden flex flex-col items-center transition duration-300 ease-in-out transform hover:bg-blue-500 hover:text-white hover:scale-105">
//               <div className="w-24 h-24 mt-8 mb-4">{service.icon}</div>
//               <div className="px-6 py-4 text-center">
//                 <h3 className="text-xl font-semibold mb-2">{service.title}</h3>
//                 <p className="text-gray-600">{service.description}</p>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// };

// export default ServiceSection;
