import React, { Component } from "react";
import { FaMapMarkerAlt, FaPhoneAlt, FaFax, FaEnvelope } from 'react-icons/fa';
import { AiFillFacebook } from 'react-icons/ai';
import { FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import SectionHeader from '../components/SectionHeader';
import axios from "axios";
const url = "http://apii.alphasteelet.com";
const axiosInstance = axios.create({ url });

class Contact  extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
      sent: false,
    };
  }

  // hundale inputs
  handleName = (e) => {
    this.setState({
      ...this.state,
      name: e.target.value,
    });
  };

  handleEmail = (e) => {
    this.setState({
      ...this.state,
      email: e.target.value,
    });
  };

  handleShow =()=>{
    this.setState({
      ...this.state,
      sent: false,
    }); 
  }

  handleMessage = (e) => {
    this.setState({
      ...this.state,
      message: e.target.value,
    });
  };

  //end of handle inputs

  //formSubmit

  formSubmit = (e) => {
    e.preventDefault();
    console.log(this.state);

    axiosInstance
      .post(`${url}/api/forma`, this.state)
      .then((res) => {
        this.setState({
          sent: true,
        });
     
        this.resetForm();
      })
      .catch(() => {
        console.log("message  not sent");
      });
  };
  /// for reseting initial

  resetForm = () => {
    this.setState({
      name: "",
      email: "",
      message: "",
    });
    setTimeout(() => {
      this.setState({
        sent: false,
      });
    }, 10000);

   
  };
  render() {
    return (
    <div className="bg-gray-100 pt-32  min-h-screen">
      <SectionHeader 
        title="Contact Us" 
        description="Have questions or need assistance? Reach out to us using the form below or contact us directly."
      className=""
      />
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8 flex flex-wrap justify-between">
        {/* Contact Information Section */}
        <div
      data-aos="flip-left"
      data-aos-easing="ease-out-cubic"
      data-aos-duration="2000"
      className="w-full md:w-1/2 bg-gray-800 text-white py-12 px-8"
    >
      <div className="text-center">
        <h2 className="text-3xl text-white font-extrabold">Contact Information</h2>
        <div className="mt-8 flex flex-col space-y-4">
          <div className="flex items-start justify-start space-x-4">
            <FaMapMarkerAlt size={24} />
            <span className="items-center flex justify-start">
              Opposite To Huigang Logistics, Zhenggang Road, Economic Development Zone, Botou City, Hebei Province,
              China.
            </span>
          </div>
          <div className="flex items-center space-x-4">
            <FaPhoneAlt size={24} />
            <span>Tel- 86-15531759619</span>
          </div>
          <div className="flex items-center space-x-4">
            <FaFax size={24} />
            <span>Fax- 86-317-8185190</span>
          </div>
          <div className="flex items-center space-x-4">
            <FaEnvelope size={24} />
            <span>info@gaiweike-manufacturing.com</span>
          </div>
          {/* Social Media Icons */}
          <div className="flex items-center space-x-4">
            <a href="https://www.facebook.com">
              <AiFillFacebook size={24} />
            </a>
            <a href="https://www.twitter.com">
              <FaInstagram size={24} />
            </a>
            <a href="https://www.instagram.com">
              <FaLinkedin size={24} />
            </a>
            <a href="https://www.instagram.com">
              <FaTwitter size={24} />
            </a>
          </div>
        </div>
      </div>
    </div>
        
        {/* Contact Form Section */}
        {this.state.sent && (
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
            <div className="relative w-auto max-w-md mx-auto my-6">
              <div className="relative bg-white rounded-lg shadow-lg">
                <div className="p-6">
                  <div className="flex items-center justify-between">
                    <svg
                      className="w-6 h-6 text-green-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    <button
                      className="text-gray-500 hover:text-gray-700 focus:outline-none"
                     
                      onClick={this.handleShow} 
                    >
                      <span className="sr-only">Close</span>
                      <svg
                        className="w-6 h-6 fill-current"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M18.364 5.636a1 1 0 010 1.414L13.414 12l4.95 4.95a1 1 0 11-1.414 1.414L12 13.414l-4.95 4.95a1 1 0 11-1.414-1.414L10.586 12 5.636 7.05a1 1 0 011.414-1.414L12 10.586l4.95-4.95a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="mt-4">
                    <p className="text-lg font-medium text-gray-900">Your message has been sent!</p>
                    <p className="mt-1 text-sm text-gray-500">
                      We will get back to you as soon as possible.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div data-aos="flip-right"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000"  className="w-full md:w-1/2">
          <div className="text-center">
            <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-md">
              <h2 className="text-3xl font-bold text-center mb-6">Contact Us</h2>

           
              <form className="space-y-4"  onSubmit={this.formSubmit} >
                <div>
                  <input onChange={this.handleName}
                    type="text"
                     value={this.state.name} id="name" name="name" required placeholder="Your Name" className="w-full px-4 py-2 border  border-gray-300 rounded-md focus:outline-none focus:border-blue-400"/>
                </div>
                <div>
                  <input     onChange={this.handleEmail}
                  value={this.state.email}
                    type="email" id="email" name="email" required placeholder="Your Email" className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400"/>
                </div>
                
                <div>
                  <textarea id="message"   value={this.state.message} onChange={this.handleMessage} name="message" rows="4" required placeholder="Your Message" className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400"></textarea>
                </div>
                <button type="submit" className="w-full bg-blue-500 text-white font-semibold py-2 px-4 rounded-md hover:bg-blue-600">Submit</button>
              </form>
            </div>
          </div>
        </div>


       
      </div>
      <div  className="max-w-7xl mx-auto py-2 px-4 sm:px-6 lg:px-8">
  <div className="text-center">
    <div className="mt-8">
    
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d25127.8570659125!2d116.61276634269909!3d38.07079720233946!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x35e932781bba76f9%3A0xb617d0d98f4eecc0!2sZhenggang%20Road!5e0!3m2!1sen!2set!4v1712046704196!5m2!1sen!2set"  title="Google Maps"
        width="100%"
        height="400" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
  </div>
</div>

    </div>
  );
}
}

export default Contact;
