import React, { useContext } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import { EffectFade, Autoplay } from 'swiper';
import Img1 from '../assets/img/heroSlider/updated2.jpg';
import Img2 from '../assets/img/heroSlider/updated1.jpg';
import Img4 from '../assets/img/heroSlider/Banner4.jpg';
import { RoomContext } from '../context/RoomContext';
import { Link } from 'react-router-dom';

const slides = [
  {
    title: 'Crafting Quality Machines with Gaweike .',
    bg: Img1,
    btnText: 'Explore Our Machines'
  },
  {
    title: 'Innovation in Every Gaweike Machine',
    bg: Img2,
    btnText: 'Explore Our Machines'
  },
  {
    title: 'Lifting Solutions, Building Success',
    bg: Img4,
    btnText: 'Explore Our Machines'
  },
  


];

const HeroSlider = () => {
  const { handleScroll } = useContext(RoomContext);

  return (
    <Swiper
      modules={[EffectFade, Autoplay]}
      effect={'fade'}
      loop={true}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false
      }}
      className='heroSlider h-screen  bg-center lg:bg-cover bg-no-repeat bg-fixed relative z-20'
    >
      {slides.map((slide, index) => {
        const { title, bg, btnText } = slide;
        return (
          <SwiperSlide
            className='h-full  relative'
            key={index}
          >
            <img src={bg} alt='' className='absolute inset-0 object-cover w-full h-full' />
            <div   data-aos="zoom-out"
  
  data-aos-duration="2000"
  data-aos-offset="0" className='absolute inset-0 pt-28 flex flex-col justify-center items-center text-white text-center'>
              <h1 
              
              className='text-4xl text-white  max-w-3xl lg:text-6xl mb-6 font-bold z-30'>{title}</h1>
              <p  
                   className='mb-6 text-lg max-w-xl lg:text-xl text-white z-30'>
              Our machines exceed industry standards, empowering customers and we craft quality equipments.</p>             
              <Link to="/products">
               <button className='bg-accent hover:bg-blue-800 text-white font-bold py-3 px-6 rounded z-30'>
                {btnText} <i className='ri-arrow-right-line text-accent'></i>
              </button>
              </Link>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default HeroSlider;
