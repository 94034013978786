import React from 'react';

const SectionHeader = ({ title, description }) => {
  return (
    <div className="text-center items-center flex flex-col gap-4 pt-8">
      <h2 className="text-3xl  font-semibold text-gray-800">{title}</h2>
      <p className="text-gray-600 max-w-3xl">{description}</p>
    </div>
  );
};

export default SectionHeader;
