import React from 'react';
import { useParams } from 'react-router-dom';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa'; // Import React Icons
import MyGallery from '../components/MyGallery';
import SectionHeader from '../components/SectionHeader';

const ProductDetail = ({ products }) => {
  const { productId } = useParams(); // Get productId from URL parameters

  // Find the product with the matching productId
  const product = products.find(product => product.id === parseInt(productId));

  // Return null if product is not found
  if (!product) {
    return <div className="text-center text-gray-600 py-8">Product not found!</div>;
  }

  // Destructure technical details from the product object
  const { technicalDetails } = product;

  return (
    <div className="max-w-4xl mx-auto  pt-28 px-4">
      <SectionHeader
        title="Product Details"
        description="Explore the technical specifications of our product."
      />

      <div className="bg-white rounded-lg shadow-md overflow-hidden grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
          <MyGallery images={product.images} className="object-cover w-full h-96" />
        </div>
        <div className="p-6 flex flex-col justify-between">
          <div>
            <h2 className="text-2xl font-bold text-gray-800 mb-4">{product.name}</h2>
            <p className="text-gray-600 leading-relaxed mb-8">{product.description}</p>
          </div>
          {technicalDetails && ( // Check if technicalDetails exist
            <div>
              <h3 className="text-xl font-bold text-gray-800 mb-2">Technical Details</h3>
              <ul className="text-gray-600">
                <li><strong>Raw material:</strong> {technicalDetails.rawMaterial}</li>
                <li><strong>Thickness of coil sheet:</strong> {technicalDetails.thicknessOfCoilSheet}</li>
                {technicalDetails.machineDetails && ( // Check if machineDetails exist
                  <>
                    <li><strong>Roller material:</strong> {technicalDetails.machineDetails.rollerMaterial}</li>
                    <li><strong>Shaft material:</strong> {technicalDetails.machineDetails.shaftMaterial}</li>
                    {/* Add more technical details here */}
                  </>
                )}
              </ul>
            </div>
          )}
          <div className="flex items-center space-x-4 mt-8">
            <a href="#" className="text-gray-500 hover:text-gray-800"><FaFacebook size={24} /></a>
            <a href="#" className="text-gray-500 hover:text-gray-800"><FaTwitter size={24} /></a>
            <a href="#" className="text-gray-500 hover:text-gray-800"><FaInstagram size={24} /></a>
          </div>
          <button className="mt-8 bg-blue-500 hover:bg-blue-600 text-white font-semibold py-3 px-8 rounded-md transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none">Contact Us Now</button>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
